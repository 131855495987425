import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import SanityLink from './SanityLink';
import headerLogo from '../images/header-logo.svg';

export default function Header() {
	const data = useStaticQuery(graphql`
		query HeaderQuery {
			header: sanitySettingsHeader {
				mobileMenu {
					items {
						...navItems
					}
				}
				menuLeft {
					items {
						...navItems
					}
				}
				menuRight {
					items {
						...navItems
					}
				}
			}
		}
	`);
	const menuLeft = data.header.menuLeft.items;
	const menuRight = data.header.menuRight.items;
	const mobileMenu = data.header.mobileMenu.items;
	const [menu, setMenu] = useState('');
	const toggleMenu = () => {
		setMenu(menu === '' ? 'is-open' : '');
		document.body.classList.toggle('overflow-hidden');
	};
	return (
		<>
			<header className="header z-20 sticky top-0 h-12 lg:h-16 bg-primary grid grid-cols-1 lg:grid-cols-3 justify-center items-center wrapper">
				<button
					onClick={toggleMenu}
					className={`mobile-menu__trigger ${menu}`}
				>
					<span></span>
					<span></span>
					<span></span>
					<span className="sr-only">Open menu</span>
				</button>

				<div className="hidden lg:flex items-center h-full">
					<nav className={`header-menu`} role="navigation">
						<ul className="flex w-full h-full space-x-4 text-sm ">
							{menuLeft.map((link) => {
								return (
									<li
										key={link._key}
										className="h-full header-menu__item"
									>
										<SanityLink
											link={link.navigationItemUrl}
											className="header-menu__link"
										>
											{link.text}
										</SanityLink>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
				<div className="flex justify-center">
					<Link to="/">
						<img
							src={headerLogo}
							alt="Bocca Italian"
							className="h-6 lg:h-8"
						/>
					</Link>
				</div>
				<div className="hidden lg:flex items-center h-full justify-end">
					<nav className={`header-menu`} role="navigation">
						<ul className="flex w-full h-full space-x-4 text-sm ">
							{menuRight.map((link) => {
								return (
									<li
										key={link._key}
										className="header-menu__item"
									>
										<SanityLink
											link={link.navigationItemUrl}
											className="header-menu__link"
										>
											{link.text}
										</SanityLink>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			</header>
			<div className={`mobile-menu ${menu}`}>
				<nav
					className="p-8 flex justify-center h-full"
					role="navigation"
				>
					<ul className="flex flex-col items-center justify-center space-y-4">
						{mobileMenu.map((link) => {
							return (
								<li key={link._key} className="">
									<SanityLink
										link={link.navigationItemUrl}
										className="text-light text-xl  hover:opacity-75 duration-500"
										onClick={toggleMenu}
									>
										{link.text}
									</SanityLink>
								</li>
							);
						})}
					</ul>
				</nav>
			</div>
		</>
	);
}
