import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SanityLink from './SanityLink';
import footerBadge from '../images/footer-badge.svg';
import Instagram from './Instagram';
import SiteCredits from './SiteCredits';

const facebookIcon = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M31.5 16.0938C31.5 7.53125 24.5625 0.59375 16 0.59375C7.4375 0.59375 0.5 7.53125 0.5 16.0938C0.5 23.8438 6.125 30.2812 13.5625 31.4062V20.5938H9.625V16.0938H13.5625V12.7188C13.5625 8.84375 15.875 6.65625 19.375 6.65625C21.125 6.65625 22.875 6.96875 22.875 6.96875V10.7812H20.9375C19 10.7812 18.375 11.9688 18.375 13.2188V16.0938H22.6875L22 20.5938H18.375V31.4062C25.8125 30.2812 31.5 23.8438 31.5 16.0938Z"
			fill="#F15A22"
		/>
	</svg>
);

const instagramIcon = (
	<svg
		width="28"
		height="28"
		viewBox="0 0 28 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.0312 6.78125C10.0312 6.78125 6.84375 10.0312 6.84375 13.9688C6.84375 17.9688 10.0312 21.1562 14.0312 21.1562C17.9688 21.1562 21.2188 17.9688 21.2188 13.9688C21.2188 10.0312 17.9688 6.78125 14.0312 6.78125ZM14.0312 18.6562C11.4688 18.6562 9.34375 16.5938 9.34375 13.9688C9.34375 11.4062 11.4062 9.34375 14.0312 9.34375C16.5938 9.34375 18.6562 11.4062 18.6562 13.9688C18.6562 16.5938 16.5938 18.6562 14.0312 18.6562ZM23.1562 6.53125C23.1562 7.46875 22.4062 8.21875 21.4688 8.21875C20.5312 8.21875 19.7812 7.46875 19.7812 6.53125C19.7812 5.59375 20.5312 4.84375 21.4688 4.84375C22.4062 4.84375 23.1562 5.59375 23.1562 6.53125ZM27.9062 8.21875C27.7812 5.96875 27.2812 3.96875 25.6562 2.34375C24.0312 0.71875 22.0312 0.21875 19.7812 0.09375C17.4688 -0.03125 10.5312 -0.03125 8.21875 0.09375C5.96875 0.21875 4.03125 0.71875 2.34375 2.34375C0.71875 3.96875 0.21875 5.96875 0.09375 8.21875C-0.03125 10.5312 -0.03125 17.4688 0.09375 19.7812C0.21875 22.0312 0.71875 23.9688 2.34375 25.6562C4.03125 27.2812 5.96875 27.7812 8.21875 27.9062C10.5312 28.0312 17.4688 28.0312 19.7812 27.9062C22.0312 27.7812 24.0312 27.2812 25.6562 25.6562C27.2812 23.9688 27.7812 22.0312 27.9062 19.7812C28.0312 17.4688 28.0312 10.5312 27.9062 8.21875ZM24.9062 22.2188C24.4688 23.4688 23.4688 24.4062 22.2812 24.9062C20.4062 25.6562 16.0312 25.4688 14.0312 25.4688C11.9688 25.4688 7.59375 25.6562 5.78125 24.9062C4.53125 24.4062 3.59375 23.4688 3.09375 22.2188C2.34375 20.4062 2.53125 16.0312 2.53125 13.9688C2.53125 11.9688 2.34375 7.59375 3.09375 5.71875C3.59375 4.53125 4.53125 3.59375 5.78125 3.09375C7.59375 2.34375 11.9688 2.53125 14.0312 2.53125C16.0312 2.53125 20.4062 2.34375 22.2812 3.09375C23.4688 3.53125 24.4062 4.53125 24.9062 5.71875C25.6562 7.59375 25.4688 11.9688 25.4688 13.9688C25.4688 16.0312 25.6562 20.4062 24.9062 22.2188Z"
			fill="#F15A22"
		/>
	</svg>
);

export default function Footer() {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			socials: sanitySettingsGeneral {
				facebookUrl
				instagramUrl
			}
			footer: sanitySettingsFooter {
				footerMenu {
					items {
						...navItems
					}
				}
				footerColumns {
					_key
					heading
					text
					button {
						...navItems
					}
				}
			}
		}
	`);
	const menu = data.footer.footerMenu.items;
	const columns = data.footer.footerColumns;

	return (
		<section className="theme--footer">
			<Instagram />
			<footer className="z-10 bg-pink flex flex-col items-center justify-center px-4vw pt-16">
				<div className="relative px-4 pt-32 pb-16 flex flex-col items-center justify-center border-2 border-primary w-full max-w-5xl">
					<div className="absolute top-0 transform -translate-y-1/2">
						<img
							src={footerBadge}
							alt=""
							className="w-48 animate-spin-slow"
						/>
					</div>
					<ul className="flex flex-wrap w-full text-sm justify-center -p-1 mb-8">
						{menu.map((link) => {
							return (
								<li key={link._key} className="p-1">
									<SanityLink
										link={link.navigationItemUrl}
										className="footer-button text-center text-light inline-block bg-primary px-8 py-3 rounded-full text-xs font-bold uppercase tracking-wider hover:opacity-75 duration-500"
									>
										{link.text}
									</SanityLink>
								</li>
							);
						})}
					</ul>

					<div className="flex flex-wrap w-full justify-center mb-8 md:mb-0">
						{columns.map((col) => {
							return (
								<div
									key={col._key}
									className="p-1 w-full md:w-1/3 text-center mb-4 md:mb-8"
								>
									<h6>{col.heading}</h6>
									<p
										dangerouslySetInnerHTML={{
											__html: col.text,
										}}
									></p>
									{col.button && (
										<SanityLink
											link={col.button.navigationItemUrl}
											className="text-xs underline"
										>
											{col.button.text}
										</SanityLink>
									)}
								</div>
							);
						})}
					</div>

					<ul className="flex items-center space-x-4">
						{data.socials.instagramUrl && (
							<li>
								<a
									href={data.socials.instagramUrl}
									target="_blank"
									rel="noreferrer"
									className="hover:opacity-75 duration-500"
								>
									{instagramIcon}
								</a>
							</li>
						)}
						{data.socials.facebookUrl && (
							<li>
								<a
									href={data.socials.facebookUrl}
									target="_blank"
									rel="noreferrer"
									className="hover:opacity-75 duration-500"
								>
									{facebookIcon}
								</a>
							</li>
						)}
					</ul>
				</div>
				<SiteCredits />
			</footer>
		</section>
	);
}
