import React from 'react';
import { Link } from 'gatsby';

const SanityLink = ({ children, link, ...props }) => {
	const internalSanityLink = link.linkType === 'internal';
	const externalSanityLink = link.linkType === 'external';

	if (internalSanityLink) {
		if (link.internalLink._type === 'homePage') {
			return (
				<Link to={`/`} {...props} activeClassName="current">
					{children}
				</Link>
			);
		} else {
			return (
				<Link
					to={`/${link.internalLink.slug.current}`}
					{...props}
					activeClassName="current"
				>
					{children}
				</Link>
			);
		}
	}
	if (externalSanityLink) {
		return (
			<a
				href={link.href}
				target={link.blank ? `_blank` : null}
				{...props}
			>
				{children}
			</a>
		);
	}
};

export default SanityLink;
