import React, { useState } from 'react';

export default function SiteCredits() {
	const [state, setState] = useState('');
	const toggleState = () => {
		setState(state === '' ? 'is-open' : '');
		document.body.classList.toggle('overflow-hidden');
	};

	return (
		<>
			<div className="py-2">
				<button
					className="text-xxs uppercase font-bold"
					onClick={toggleState}
				>
					Site Credits
				</button>
			</div>
			<div className={`credits ${state}`}>
				<div className="credits__content w-full max-w-xs bg-white rounded-lg p-6 relative">
					<button
						className="h-10 w-10 rounded-full bg-primary absolute -top-5 -right-5"
						onClick={toggleState}
					>
						<span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45 h-0.5 w-5 bg-light"></span>
						<span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-45 h-0.5 w-5 bg-light"></span>
					</button>
					<h2 className="text-base">Site Credits:</h2>
					<ul className="text-sm">
						<li>
							Website design & build:{' '}
							<a
								href="https://madebyverse.com/"
								target="_blank"
								rel="noreferrer"
								className="underline"
							>
								Verse
							</a>
						</li>
						<li>
							Branding:{' '}
							<a
								href="https://acgcreativeco.com/"
								target="_blank"
								rel="noreferrer"
								className="underline"
							>
								ACG Creative
							</a>
						</li>

						<li>
							Photography:{' '}
							<a
								href="https://cieranmurphy.com/"
								target="_blank"
								rel="noreferrer"
								className="underline"
							>
								Cieran Murphy
							</a>{' '}
							&{' '}
							<a
								href="https://coreysphoto.com/"
								target="_blank"
								rel="noreferrer"
								className="underline"
							>
								Corey Schweikert
							</a>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}
